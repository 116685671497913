<template>
  <div>
    <v-dialog
      v-model="addDialogue"
      max-width="500px"
      transition="dialog-transition"
      persistent
    >
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card>
            <v-layout wrap justify-center pa-4>
              <v-flex xs12>
                <v-layout wrap justify-center pb-4>
                  <v-flex xs12>
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 20px;
                        color: #000000;
                      "
                      >Add New Stock</span
                    >
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Size </span>
                    <v-select
                      v-if="!addSizeDialogue"
                      :disabled="addSizeDialogue ? false : true"
                      outlined
                      dense
                      color="#FF1313"
                      placeholder="Size"
                      v-model="selectedStock.size"
                      :items="sizes"
                      item-text="value"
                      item-value="value"
                      item-color="#FF1313"
                      hide-details
                      class="text-left"
                    >
                      <template v-slot:item="{ item }">
                        <span class="text-left">
                          {{ item }}
                        </span>
                      </template>
                    </v-select>
                    <v-select
                      v-if="addSizeDialogue"
                      outlined
                      dense
                      color="#FF1313"
                      placeholder="Size"
                      v-model="newSize"
                      item-disabled="disabled"
                      :items="sizes"
                      item-text="value"
                      item-value="value"
                      item-color="#FF1313"
                      hide-details
                      class="text-left"
                    >
                      <template v-slot:item="{ item }">
                        <span class="text-left">
                          {{ item.value }}
                        </span>
                      </template>
                    </v-select>
                  </v-flex>
                  <v-flex xs6 sm4 px-2 text-left v-if="addSizeDialogue">
                    <span class="styloopinputlabel"> Color Available </span>
                    <v-switch
                      v-model="colorStatus"
                      color="#5DC57C"
                      hide-details
                      :ripple="false"
                      class="statusKey pt-2"
                    >
                      <template slot="label">
                        <span
                          style="
                            color: #000000;
                            font-family: opensanssemibold;
                            font-size: 16px;
                          "
                          v-text="colorStatus ? 'Yes' : 'No'"
                        >
                        </span>
                      </template>
                    </v-switch>
                  </v-flex>
                  <v-flex v-if="colorStatus" xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> color </span>
                    <br />
                    <input
                      style="width: 100%; height: 40px"
                      type="color"
                      id="newColor"
                      name="newColor"
                      value="#000000"
                    />
                  </v-flex>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Stock </span>
                    <v-text-field
                      v-model="newStock"
                      type="number"
                      color="#FF1313"
                      hide-selected
                      outlined
                      placeholder="Available Stock"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-end py-4>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      block
                      tile
                      color="#757575"
                      outlined
                      :ripple="false"
                      depressed
                      @click="addDialogue = false"
                    >
                      <span
                        style="
                          font-family: kumbhsansregular;
                          font-size: 16px;
                          text-transform: none;
                          color: #000000;
                        "
                      >
                        Cancel
                      </span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      v-if="!addSizeDialogue"
                      tile
                      block
                      dark
                      color="#FF1313"
                      depressed
                      class="text-capitalize"
                      :ripple="false"
                      @click="addStock"
                    >
                      Apply
                    </v-btn>
                    <v-btn
                      v-if="addSizeDialogue"
                      tile
                      block
                      dark
                      color="#FF1313"
                      depressed
                      class="text-capitalize"
                      :ripple="false"
                      @click="addSizeStock"
                    >
                      Apply
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
    <v-dialog
      v-model="editDialogue"
      max-width="500px"
      transition="dialog-transition"
      persistent
    >
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-card>
            <v-layout wrap justify-center pa-4>
              <v-flex xs12>
                <v-layout wrap justify-center pb-4>
                  <v-flex xs12>
                    <span
                      style="
                        font-family: poppinssemibold;
                        font-size: 20px;
                        color: #000000;
                      "
                      >Edit Stock</span
                    >
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-start>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Size </span>
                    <v-select
                      v-if="!addSizeDialogue"
                      :disabled="addSizeDialogue ? false : true"
                      outlined
                      dense
                      color="#FF1313"
                      placeholder="Size"
                      v-model="selectedStock.size"
                      :items="sizes"
                      item-text="value"
                      item-value="value"
                      item-color="#FF1313"
                      hide-details
                      class="text-left"
                    >
                      <template v-slot:item="{ item }">
                        <span class="text-left">
                          {{ item }}
                        </span>
                      </template>
                    </v-select>
                    <v-select
                      v-if="addSizeDialogue"
                      outlined
                      dense
                      color="#FF1313"
                      placeholder="Size"
                      v-model="newSize"
                      item-disabled="disabled"
                      :items="sizes"
                      item-text="value"
                      item-value="value"
                      item-color="#FF1313"
                      hide-details
                      class="text-left"
                    >
                      <template v-slot:item="{ item }">
                        <span class="text-left">
                          {{ item.value }}
                        </span>
                      </template>
                    </v-select>
                  </v-flex>

                  <v-flex v-if="colorStatus" xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> color </span>
                    <br />
                    <input
                      style="width: 100%; height: 40px"
                      type="color"
                      id="editColor"
                      name="editColor"
                      :value="selectedStock.color"
                    />
                  </v-flex>
                  <v-flex xs6 sm4 px-2 text-left>
                    <span class="styloopinputlabel"> Stock </span>
                    <v-text-field
                      v-model="selectedStock.stock"
                      type="number"
                      color="#FF1313"
                      hide-selected
                      outlined
                      placeholder="Available Stock"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap justify-end py-4>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      block
                      tile
                      color="#757575"
                      outlined
                      :ripple="false"
                      depressed
                      @click="editDialogue = false"
                    >
                      <span
                        style="
                          font-family: kumbhsansregular;
                          font-size: 16px;
                          text-transform: none;
                          color: #000000;
                        "
                      >
                        Cancel
                      </span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs6 sm4 px-2>
                    <v-btn
                      v-if="!addSizeDialogue"
                      tile
                      block
                      dark
                      color="#FF1313"
                      depressed
                      class="text-capitalize"
                      :ripple="false"
                      @click="editStock"
                    >
                      Apply
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
    <template v-for="(item, index1) in productStock">
      <v-layout wrap justify-start :key="index1">
        <v-flex xs6 sm2 pa-2>
          <v-card color="#000000" height="50px" flat>
            <v-layout wrap justify-center fill-height>
              <v-flex xs4 align-self-center>
                <span
                  style="
                    font-family: opensansbold;
                    font-size: 14px;
                    color: #ffffff;
                  "
                >
                  Size
                </span>
              </v-flex>
              <v-flex xs4 align-self-center>
                <span
                  style="
                    font-family: opensansbold;
                    font-size: 14px;
                    color: #ffffff;
                  "
                >
                  {{ item.size }}
                </span>
              </v-flex>
            </v-layout>
          </v-card>
          <v-badge color="transparent" offset-x="-50" offset-y="-60">
            <template v-slot:badge>
              <v-avatar color="#FF3434" size="15">
                <v-icon
                  color="#FFF"
                  size="13px"
                  @click.prevent="productStock.splice(index1, 1)"
                >
                  mdi-close
                </v-icon>
              </v-avatar>
            </template>
          </v-badge>
        </v-flex>
        <v-flex xs12 sm10 pl-2>
          <v-layout wrap justify-start>
            <v-flex xs6 sm2 v-for="(item2, i) in item.stock" :key="i" pa-2>
              <v-card outlined flat height="50px" >
                <v-layout wrap justify-center>
                  <v-flex xs6>
                    <v-layout wrap justify-center>
                      <v-flex xs12 pl-1>
                        <span
                          style="
                            font-family: opensanslight;
                            font-size: 14px;
                            color: #848484;
                          "
                        >
                          Color
                        </span>
                      </v-flex>
                      <v-flex
                        xs12
                        text-center
                        align-self-center
                        v-if="item2.color"
                      >
                        <v-avatar :color="item2.color" size="15"></v-avatar>
                      </v-flex>
                      <v-flex xs12 text-center align-self-center v-else>
                        -
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6>
                    <v-layout wrap justify-center>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: opensanslight;
                            font-size: 14px;
                            color: #65ce96;
                          "
                        >
                          Stock
                        </span>
                      </v-flex>
                      <v-flex xs12>
                        <span
                          style="
                            font-family: opensansbold;
                            font-size: 14px;
                            color: #000000;
                          "
                        >
                          {{ item2.stock }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
              <v-badge color="transparent" offset-x="-40" offset-y="-60">
                <template v-slot:badge>
                  <v-avatar color="#FF3434" size="15">
                    <v-icon
                      color="#FFF"
                      size="13px"
                      @click.prevent="item.stock.splice(i, 1)"
                    >
                      mdi-close
                    </v-icon>
                  </v-avatar>
                </template>
              </v-badge>
              <v-badge color="transparent" offset-x="-20" offset-y="-60">
                <template v-slot:badge>
                  <v-avatar color="#FF3434" size="15">
                    <v-icon
                      color="#FFF"
                      size="13px"
                      @click.prevent="stockEditor(item, item2)"
                    >
                      mdi-pencil
                    </v-icon>
                  </v-avatar>
                </template>
              </v-badge>
            </v-flex>
            <v-flex v-if="item.color" xs2 pa-2>
              <v-card
                outlined
                height="50px"
                width="100%"
                @click="stockAdder(item)"
              >
                <v-layout wrap justify-center fill-height>
                  <v-flex xs12 align-self-center text-center>
                    <v-icon>mdi-plus</v-icon>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </template>
    <v-layout wrap justify-start v-if="productStock.length != sizes.length">
      <v-flex xs12 sm2 pa-2>
        <v-card
          color="#000000"
          height="50px"
          width="100%"
          flat
          @click="stockAdder('new')"
        >
          <v-layout wrap justify-center fill-height>
            <v-flex xs6 align-self-center>
              <span
                style="
                  font-family: opensansbold;
                  font-size: 14px;
                  color: #ffffff;
                "
              >
                Add Size
              </span>
            </v-flex>
            <v-flex xs4 align-self-center>
              <v-icon size="14px" color="#ffffff">mdi-plus</v-icon>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage", "sizeData"],
  data() {
    return {
      addDialogue: false,
      editDialogue: false,
      addSizeDialogue: false,
      newSize: null,
      newStock: 0,
      colorStatus: false,
      newColor: null,
      productStock: [],
      selectedStock: {},
      sizes: [],
    };
  },
  mounted() {
    if (this.sizeData) this.productStock = this.sizeData;
    this.sizes = [];
    if (this.storage) {
      for (var i = 0; i < this.storage.length; i++) {
        var obj = {};
        obj.value = this.storage[i];
        obj.disabled = false;
        this.sizes.push(obj);
      }
    }
  },
  watch: {
    productStock: {
      handler: function () {
        var temparr = [];
        for (var i = 0; i < this.productStock.length; i++) {
          temparr.push(this.productStock[i].size);
        }
        for (var k = 0; k < this.sizes.length; k++) {
          var flag = false;
          for (var j = 0; j < temparr.length; j++) {
            if (temparr[j] == this.sizes[k].value) {
              this.sizes[k].disabled = true;
              flag = true;
            }
          }
          if (!flag) {
            this.sizes[k].disabled = false;
          }
        }
        this.$emit("stepper", {
          productStock: this.productStock,
        });
      },
      deep: true,
    },
  },

  methods: {
    stockAdder(item) {
      if (item == "new") {
        this.addSizeDialogue = true;
      } else {
        this.addSizeDialogue = false;
        this.selectedStock = item;
        if (item.color) {
          this.colorStatus = true;
        } else {
          this.colorStatus = false;
        }
      }
      this.addDialogue = true;
    },
    stockEditor(productSize, item) {
      this.editDialogue = true;
      if (item.color) {
        this.colorStatus = true;
      } else {
        this.colorStatus = false;
      }
      this.selectedStock.size = productSize.size;
      this.selectedStock.color = item.color;
      this.selectedStock.stock = item.stock;
      this.selectedStock.id = item._id;
    },
    editStock() {
      if (document.getElementById("editColor"))
        this.selectedStock.color = document.getElementById("editColor").value;
      for (var i = 0; i < this.productStock.length; i++) {
        for (var j = 0; j < this.productStock[i].stock.length; j++) {
          if (this.selectedStock.id == this.productStock[i].stock[j]._id) {
            this.productStock[i].stock[j].color = this.selectedStock.color;
            this.productStock[i].stock[j].stock = this.selectedStock.stock;
          }
        }
      }
      this.selectedStock = {};
      this.editDialogue = false;
      this.$emit("stepper", {
        productStock: this.productStock,
      });
    },
    addStock() {
      if (!this.newStock) return;

      if (document.getElementById("newColor"))
        this.newColor = document.getElementById("newColor").value;
      var obj = {};
      obj.color = this.newColor;
      obj.stock = Number(this.newStock);
      for (var i = 0; i < this.productStock.length; i++) {
        if (this.selectedStock.size == this.productStock[i].size) {
          this.productStock[i].stock.push(obj);
        }
      }
      this.newSize = null;
      this.newColor = null;
      this.newStock = null;
      this.addDialogue = false;
      this.$emit("stepper", {
        productStock: this.productStock,
      });
    },
    addSizeStock() {
      if (!this.newSize) return;

      if (document.getElementById("newColor"))
        this.newColor = document.getElementById("newColor").value;
      var obj = {};
      var obj2 = {};
      obj.stock = [];
      obj2.color = this.newColor;
      obj2.stock = Number(this.newStock);
      obj.size = this.newSize;
      obj.color = this.colorStatus;
      obj.stock.push(obj2);
      this.productStock.push(obj);
      this.newSize = null;
      this.newColor = null;
      this.newStock = null;
      this.addSizeDialogue = false;
      this.addDialogue = false;
      this.$emit("stepper", {
        productStock: this.productStock,
      });
    },
  },
};
</script>
<style >
.stockSizeSelector.v-text-field.v-text-field--solo .v-input__control {
  min-height: 50px !important;
  padding: 0 !important;
  border-radius: 5px !important;
}
</style>